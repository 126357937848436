import { AssessmentStatus } from '../../Models/AssessmentStatus';
import { TestInfoDTO } from '../Tests/TestInfoDTO';

export type ApplicantStatus =
    | 'Invited'
    | 'Applied'
    | 'Ongoing'
    | 'Completed'
    | 'Retaken'
    | 'InvitedForInterview'
    | 'Interviewing'
    | 'Rejected'
    | 'Hired';

export enum ApplicantAssessmentInfoStatus {
    NotStarted = 'NotStarted',
    AwaitingAssessment = 'AwaitingAssessment',
    AssessmentOngoing = 'AssessmentOngoing',
    Submitted = 'Submitted',
    InvitedForInterview = 'InvitedForInterview',
    Interviewing = 'Interviewing',
    Hired = 'Hired',
    Rejected = 'Rejected',
}

export interface ApplicantAssessmentInfoDTO {
    id: string;
    assessmentName: string;
    invitedAt: string;
    appliedAt?: string | null;
    startedAt?: string | null;
    completedAt?: string | null;
    tests: Array<TestInfoDTO>;
    companyName: string;
    recruiterFirstName: string;
    recruiterLastName: string;
    assessmentStatus: AssessmentStatus;
    applicantStatus: ApplicantStatus;
    companyIconURL: string | null;
}
