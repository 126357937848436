export interface UnfinishedApplication {
    assessmentId: string;
    activeTestId: string;
    companyName: string;
}

export const DefaultUnfinishedApplication: UnfinishedApplication = {
    assessmentId: '',
    activeTestId: '',
    companyName: '',
};
