import React, { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@talentmesh/core';
import InfoSection from './components/CardSections/InfoSection/InfoSection';
import StatusSection from './components/CardSections/StatusSection/StatusSection';
import AssessmentSection from './components/CardSections/AssessmentSection/AssessmentSection';
import {
    mapApplicantStatusToApplicantAssessmentStatus,
    getJobApplicationCardBackgroundColor,
} from '../../utils/JobApplicationUtil';
import { ApplicantAssessmentInfo } from '../../../../../Models/ApplicantAssessmentInfo';

interface JobApplicationCardProps {
    assessment: ApplicantAssessmentInfo;
    isUpdated: boolean;
}

function JobApplicationCard({ assessment, isUpdated }: JobApplicationCardProps) {
    const theme = useTheme();

    const { id, applicantStatus, companyName, assessmentName, companyIconURL, appliedAt } = assessment;

    const applicantAssessmentInfoStatus = mapApplicantStatusToApplicantAssessmentStatus(applicantStatus);
    const isNotStarted = appliedAt === null;

    return (
        <Box
            data-cy="JobApplicationCard"
            sx={{
                border: `2px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.mediumBorderRadius,
                backgroundColor: getJobApplicationCardBackgroundColor(theme, applicantAssessmentInfoStatus),
                overflow: 'hidden',
            }}
        >
            <StatusSection isUpdated={isUpdated} applicantAssessmentInfoStatus={applicantAssessmentInfoStatus} />
            <InfoSection
                id={id}
                companyName={companyName}
                assessmentName={assessmentName}
                companyIconURL={companyIconURL}
                isNotStarted={isNotStarted}
            />
            {!isNotStarted && (
                <AssessmentSection
                    assessment={assessment}
                    isUpdated={isUpdated}
                    applicantAssessmentInfoStatus={applicantAssessmentInfoStatus}
                />
            )}
        </Box>
    );
}

export default memo(JobApplicationCard);
