import { ApplicantStatus, ApplicantAssessmentInfoDTO } from '../DTO/Assessments/ApplicantAssessmentInfoDTO';
import { AssessmentStatus } from './AssessmentStatus';
import { Page } from './Page';
import { mapFromTestInfoDTO, TestInfo } from './TestInfo';

export const enum ApplicantAssessmentInfoFilter {
    Open = 'Open',
    Hired = 'Hired',
    Rejected = 'Rejected',
}

export interface ApplicantAssessmentInfoPagination<T> extends Page<T> {
    totalOpen: number;
    totalHired: number;
    totalRejected: number;
}

export const EmptyApplicantAssessmentInfoPage: ApplicantAssessmentInfoPagination<any> = {
    pagination: {
        offset: 0,
        limit: 0,
        totalCount: 0,
    },
    data: [],
    totalOpen: 0,
    totalHired: 0,
    totalRejected: 0,
};

export interface ApplicantAssessmentInfo {
    id: string;
    assessmentName: string;
    invitedAt: string;
    appliedAt?: string | null;
    startedAt?: string | null;
    completedAt?: string | null;
    tests: Array<TestInfo>;
    companyName: string;
    recruiterFirstName: string;
    recruiterLastName: string;
    assessmentStatus: AssessmentStatus;
    applicantStatus: ApplicantStatus;
    companyIconURL: string | null;
}
export function mapFromApplicantAssessmentInfoDTO(dto: ApplicantAssessmentInfoDTO): ApplicantAssessmentInfo {
    return {
        ...dto,
        tests: dto.tests.map((item) => mapFromTestInfoDTO(item)),
    };
}
