import { TestInfoDTO } from '../DTO/Tests/TestInfoDTO';
import { TestTypes } from './Configuration';

export enum TestStates {
    Active = 'Active',
    Inactive = 'Inactive',
    Completed = 'Completed',
}

export interface TestInfo {
    testId: string;
    name: string;
    testType: TestTypes;
    startedAt?: Date | null;
    completedAt?: Date | null;
    testState: TestStates;
    allocatedTimeMinutes?: number | null;
    estimatedTimeMinutes: number;
    totalQuestionsCount: number;
}

export const DefaultTestInfoModel: TestInfo = {
    testId: '',
    name: '',
    testState: TestStates.Inactive,
    allocatedTimeMinutes: 0,
    estimatedTimeMinutes: 0,
    totalQuestionsCount: 0,
    testType: TestTypes.None,
};

export function mapFromTestInfoDTO(dto: TestInfoDTO): TestInfo {
    return {
        testId: dto.id,
        name: dto.testType,
        testType: dto.testType,
        testState: dto.testState,
        startedAt: dto.startedAt ? new Date(dto.startedAt) : null,
        completedAt: dto.completedAt ? new Date(dto.completedAt) : null,
        allocatedTimeMinutes: dto.allocatedTimeMinutes,
        estimatedTimeMinutes: dto.estimatedTimeMinutes,
        totalQuestionsCount: dto.totalQuestionsCount,
    };
}
